import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { fingerscan } from "careplix-scan-sdk";
import { useAuth } from "../utils/auth";
import {
  scan_sdk_api_key as api_key,
  sdkURL,
  sdkWrapperURL,
} from "../utils/api-url-list";
import { logAnalyticsEvent } from "../utils/analytics";

import { ReactComponent as CarePlix } from "../assets/icons/PoweredByCarePlix.svg";
import { ReactComponent as GlowingHeart } from "../assets/icons/GlowingHeart.svg";
import Loader from "../assets/images/loader.gif";
import Analyzing from "../assets/images/analyzingNew.webp";
import Failure from "../assets/images/failureNew.webp";
import NoCamera from "../assets/images/no-cameraNew.webp";

const FingerScan = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    getToken,
    userData,
    subscriptionPlanDetails: { packageName },
  } = useAuth();
  const videoRef = useRef();
  const canvasRef = useRef();

  const [loading, setLoading] = useState(true);
  const [analyzing, setAnalyzing] = useState(false);
  const [noCameraError, setNoCameraError] = useState(false);
  const [error, setError] = useState("");
  const [scanFrameData, setScanFrameData] = useState({
    type: "error",
    message: "---",
    progress: 0,
    timeElapsed: 0,
  });
  const causeList = useRef([]);

  useEffect(() => {
    setLoading(true);

    const controller = new AbortController();
    const getScore = (scan_id = "", timeout = 0) =>
      new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            const token = await getToken();
            const scoreResp = await fetch(sdkWrapperURL("/score/get-scores"), {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: token,
              },
              body: JSON.stringify({
                profile_id: userData.profile_id,
                scan_id,
                scores_display_count: 1,
                scores_display_count_offset: 0,
                table_config_rows_per_page: 5,
                table_config_page_no: 1,
              }),
              signal: controller.signal,
            });
            const scoreRespJSON = await scoreResp.json();
            if (scoreRespJSON?.statusCode?.toString().startsWith("2")) {
              const scores = scoreRespJSON.response.map((score) => ({
                code: score.score_code,
                value: score.user_score_details?.[0]?.user_earned_score ?? "--",
                timestamp: score.user_score_details?.[0]?.score_generation_time,
              }));
              if (scores.every((score) => !(score.timestamp?.length > 0)))
                throw new Error("Scores not Generated yet.");
              else resolve(scores);
            } else throw new Error(scoreRespJSON.message);
          } catch (e) {
            reject(e.message);
          }
        }, timeout);
      });
    let duration = 0;
    const addCause = (cause = "") => {
      const possibleErrors = {
        FIINT01: "Please check your internet connection & try again.",
        FISCN01: "Finger not detected.",
        FISCN02: "Sorry, Finger Scan is not supported on this device.",
        CMSCN01: "Sorry we're unable to compute the signal. Please try again.",
        CMUSR01: "We are not able to access the Camera. Please try again.",
        CMUSR02:
          "App functionality disabled in the Background. Keep it in the Foreground for proper operation.",
      };
      const errorCode =
        Object.keys(possibleErrors).find(
          (code) => possibleErrors[code] === cause
        ) ?? "UNKNOWN";
      const lastItem = causeList.current[causeList.current.length - 1];
      if (lastItem?.code !== errorCode) {
        if (!(lastItem?.duration > 3)) causeList.current.pop();
        causeList.current.push({ code: errorCode, duration });
      }
    };

    fingerscan.onFrame((fd) => {
      setScanFrameData(fd);
      if (fd.type === "error") addCause(fd.message);
      duration = Math.round(fd.timeElapsed / 1000);
    });
    fingerscan.onError((err) => {
      console.error(err);
      if (
        err.message ===
        "We are not able to access the Camera. Please try again."
      )
        setNoCameraError(true);
      else setError(err.message);
      addCause(err.message);
      fetch(sdkURL("/vitals/update-scan-status"), {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          api_key,
          scan_token: state?.scanToken,
          employee_id: userData.profile_id,
          fire_type: "on_error",
          fire_reason: "fingerscan.onError",
          error_list: causeList.current,
        }),
      })
        .then((resp) => resp.json())
        .then((json) => {
          if (!json.statusCode?.toString().startsWith("2"))
            throw new Error(json.message ?? "Unable to send Error logs.");
        })
        .catch(console.error);
    });
    fingerscan.onScanFinish(
      async ({ raw_intensity, ppg_time, average_fps }) => {
        let scanToken = "";
        try {
          setAnalyzing(true);
          if (
            Date.now() < (state?.scanTokenExpiry ?? 0) &&
            state?.scanToken?.length > 0
          )
            scanToken = state.scanToken;
          else {
            const token = await getToken();
            const scanTokenResp = await fetch(
              sdkWrapperURL("/users/sdk/test_api_private"),
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: token,
                },
                body: JSON.stringify({
                  action: "create_scan_UL",
                  employee_id: userData.user_id,
                  profile_id: userData.profile_id,
                }),
                signal: controller.signal,
              }
            );
            const scanTokenRespJSON = await scanTokenResp.json();
            if (scanTokenRespJSON?.statusCode?.toString().startsWith("2"))
              scanToken = scanTokenRespJSON.scan_token;
            else
              throw new Error(
                scanTokenRespJSON?.message ?? "Failed to Fetch Scan Token"
              );
          }
          const addScanResp = await fetch(sdkURL("/vitals/add-scan"), {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              package: packageName,
              api_key,
              scan_token: scanToken,
              posture: "resting",
              dob: userData.dob,
              gender: userData.gender,
              metadata: {
                physiological_scores: {
                  height: userData.height,
                  weight: userData.weight,
                },
                ppg_time: ppg_time,
                raw_intensity: raw_intensity,
                device: `RPPG_CAREPLIX_FINGER_${
                  /iPhone|iPad|iPod|Macintosh|Mac/i.test(navigator.userAgent)
                    ? "IOS"
                    : "ANDROID"
                }`,
                fps: average_fps,
              },
            }),
            signal: controller.signal,
          });
          const addScanRespJSON = await addScanResp.json();
          if (addScanRespJSON?.statusCode?.toString().startsWith("2")) {
            const scan_id = addScanRespJSON?.scan_id;
            Promise.any([
              getScore(scan_id),
              getScore(scan_id, 3000),
              getScore(scan_id, 6000),
            ])
              .then((scores) => {
                logAnalyticsEvent("finger_scan_completed", { scan_id });
                logAnalyticsEvent("scan_result", { scan_id });
                navigate("/scan-result", {
                  state: {
                    // popup_time: Date.now() + 5000,
                    result: addScanRespJSON,
                    scores,
                  },
                  replace: true,
                });
              })
              .catch(() => {
                console.error("Failed all attemps of fetching scores.");
                logAnalyticsEvent("finger_scan_completed", { scan_id });
                logAnalyticsEvent("scan_result", { scan_id });
                navigate("/scan-result", {
                  state: {
                    // popup_time: Date.now() + 5000,
                    result: addScanRespJSON,
                  },
                  replace: true,
                });
              });
          } else
            throw new Error(
              addScanRespJSON?.message ?? "Failed to Fetch Result"
            );
        } catch (err) {
          console.error(err);
          setError(err.message);
        } finally {
          fetch(sdkURL("/vitals/update-scan-status"), {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              api_key,
              scan_token: scanToken,
              employee_id: userData.profile_id,
              fire_type: "on_success",
              fire_reason: "fingerscan.onScanFinish",
              error_list: causeList.current,
            }),
          })
            .then((resp) => resp.json())
            .then((json) => {
              if (!json.statusCode?.toString().startsWith("2"))
                throw new Error(json.message ?? "Unable to send Error logs.");
            })
            .catch(console.error);
        }
      }
    );
    fingerscan
      .startScan({
        canvasElement: canvasRef.current,
        videoElement: videoRef.current,
      })
      .then(() => {
        console.log("Scan Started");
        duration = 0;
        causeList.current = [];
      })
      .catch(() => console.log("Scan Failed"))
      .finally(() => setLoading(false));
    return () => {
      fingerscan.stopScan();
      controller.abort();
    };
  }, [navigate, getToken, userData, packageName, state]);

  const scanMessages = useMemo(
    () =>
      [
        "We burn calories while we are asleep because brain activity requires energy",
        "Exercise promotes cardiovascular health, ensuring more blood and oxygen to circulate the body, helping to elevate energy",
        "Always sleep on your back straight since it allows your neck and spine in a neutral position",
        "Alcohol consumption disrupts chemical impulses between brain cells causing impulsive behaviour, slurred speech, impaired memory",
        "Meditation increases nitric oxide generation in the brain helping to lower your blood pressure",
        "Meditation reduces the production of cytokines in several recent studies, thus helping in stress reduction",
        "Laughing is good for your heart. It reduces stress and gives a boost to your immune system",
        "Lack of sleep can lead to heart disease, heart attack, or stroke",
        "Heart pumps about 2,000 gallons of blood every day",
        "Your heart will beat about 115,000 times each day",
      ].sort(() => Math.random() - 0.5),
    []
  );

  const currentMessage = useMemo(() => {
    if (scanFrameData.type === "error") return "---";
    const timeElapsed = scanFrameData.timeElapsed / 1000;
    if (timeElapsed <= 5)
      return "Keep your head upright and look straight at the camera";
    else if (timeElapsed <= 10)
      return "During the measurement, please do not speak or move";
    else if (timeElapsed <= 15)
      return "Keep your device steady throughout the vital measurement";
    else if (timeElapsed <= 20) return scanMessages[0];
    else if (timeElapsed <= 25) return scanMessages[1];
    else if (timeElapsed <= 30) return scanMessages[2];
    else if (timeElapsed <= 35) return scanMessages[3];
    else if (timeElapsed <= 40) return scanMessages[4];
    else if (timeElapsed <= 45) return scanMessages[5];
    else if (timeElapsed <= 50) return scanMessages[6];
    else if (timeElapsed <= 55) return scanMessages[7];
    else if (timeElapsed <= 60) return scanMessages[8];
    else if (timeElapsed <= 65) return scanMessages[9];
  }, [scanFrameData.timeElapsed, scanFrameData.type, scanMessages]);

  return (
    <section className="relative h-screen w-screen">
      <video
        ref={videoRef}
        id="videoInput"
        className="fixed top-4 right-4 h-px w-px"
        autoPlay
        muted
        playsInline
      />
      <canvas
        ref={canvasRef}
        id="canvasOutput"
        className="w-full h-full -scale-x-100"
      />

      {scanFrameData.type === "scan" && (
        <div className="fixed left-0 right-0 h-12 animate-scan from-[#EEEDED]/75" />
      )}

      <div className="fixed top-0 left-0 right-0 p-4 pl-1 bg-primary text-white">
        <CarePlix className="h-14" />
        <GlowingHeart className="fixed top-4 right-4 h-32 w-32" />
      </div>

      {scanFrameData.type === "error" && (
        <p className="fixed top-[40vh] -translate-y-1/2 left-8 right-8 px-1 py-0.5 rounded-full bg-error/70 text-white text-center text-sm font-medium">
          {scanFrameData.message}
        </p>
      )}

      <div className="fixed bottom-0 left-0 right-0 min-h-48 flex flex-col bg-primary text-white">
        <div className="relative shrink-0 h-2.5 border-y border-primary bg-white">
          <div
            className="absolute left-0 top-0 bottom-0 rounded-r-full bg-success shadow-inner transition-all duration-1000"
            style={{ width: `${scanFrameData.progress}%` }}
          />
        </div>
        <div className="relative grow m-4 p-4 pb-12 rounded-2xl bg-gradient-transparent">
          <div>
            <h4 className="font-semibold">
              {scanFrameData.type === "error"
                ? "Scan Stopped"
                : scanFrameData.message}
            </h4>
            <h6 className="mt-2.5 text-xs leading-tight tracking-tight">
              {currentMessage}
            </h6>
          </div>
          <button
            type="button"
            onClick={() => {
              fingerscan.stopScan();
              fetch(sdkURL("/vitals/update-scan-status"), {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  api_key,
                  scan_token: state?.scanToken,
                  employee_id: userData.profile_id,
                  fire_type: "on_halt",
                  fire_reason: "cancel-scan",
                  error_list: causeList.current,
                }),
              })
                .then((resp) => resp.json())
                .then((json) => {
                  if (!json.statusCode?.toString().startsWith("2"))
                    throw new Error(
                      json.message ?? "Unable to send Error logs."
                    );
                })
                .catch(console.error);
              navigate(-1);
            }}
            className="absolute bottom-4 right-4 text-error text-sm font-medium"
          >
            &lt;&nbsp;Cancel Scan
          </button>
        </div>
      </div>

      {analyzing && (
        <div className="fixed top-0 bottom-0 left-0 right-0 px-8 py-12 flex flex-col items-center justify-center bg-primary text-white text-center">
          <img className="shrink-0 h-32" src={Analyzing} alt="Analyzing..." />
          <h4 className="mt-8 text-xl font-bold">Analyzing Data</h4>
          <p className="mt-4 font-light">
            Continuing analysis for
            <br />
            accurate measurements...
          </p>
        </div>
      )}

      {error.length > 0 && (
        <div className="fixed top-0 bottom-0 left-0 right-0 px-8 py-12 flex flex-col items-center justify-center bg-primary text-white text-center">
          <img className="shrink-0 h-44" src={Failure} alt="Failure..." />
          <p className="mt-8 text-xl font-bold">
            Sorry, we failed to calculate
            <br />
            your measurements
          </p>
          <p className="mt-4 max-h-40 overflow-auto whitespace-pre-line font-light">
            {error}
          </p>
          <button
            className={`mt-8 w-60 px-5 py-2.5 rounded-full bg-gradient text-white font-medium${
              error === "You have exceeded daily scan limit."
                ? " invisible pointer-events-none -mb-16"
                : ""
            }`}
            type="button"
            onClick={() => {
              fingerscan.stopScan();
              navigate(-1);
            }}
          >
            Try Again
          </button>
          <button
            className="mt-4 text-white text-sm font-medium"
            type="button"
            onClick={() => {
              fingerscan.stopScan();
              navigate(-3);
            }}
          >
            Back to Dashboard
          </button>
        </div>
      )}

      {noCameraError && (
        <div className="fixed top-0 bottom-0 left-0 right-0 px-8 py-12 flex flex-col items-center justify-center bg-primary text-white text-center">
          <img className="shrink-0 h-44" src={NoCamera} alt="No Camera..." />
          <p className="mt-8 text-xl font-bold">
            No Camera
            <br />
            Permission Found
          </p>
          <p className="mt-4 max-h-40 overflow-auto whitespace-pre-line font-light">
            We are not able to access the Camera. Please try again.
          </p>
          <button
            className="mt-8 px-5 py-2.5 rounded-full bg-gradient text-white font-medium"
            type="button"
            onClick={() => {
              fingerscan.stopScan();
              navigate("/camera-permission-guide", { replace: true });
            }}
          >
            How to give camera permission?
          </button>
          <button
            className="mt-4 text-white text-sm font-medium"
            type="button"
            onClick={() => {
              fingerscan.stopScan();
              navigate(-3);
            }}
          >
            Back to Dashboard
          </button>
        </div>
      )}

      {loading && (
        <div className="fixed top-0 bottom-0 left-0 right-0 px-8 py-12 flex flex-col items-center justify-center bg-primary text-white text-center">
          <img className="shrink-0 h-20" src={Loader} alt="Loading..." />
          <h4 className="mt-20 text-xl font-bold">Downloading Scan modules</h4>
          <p className="mt-4 font-light">This might take few seconds....</p>
        </div>
      )}
    </section>
  );
};

export default FingerScan;
